.Nav-List {
  list-style: none;
  display: flex;
  width: 50%;
  justify-content: space-around;
}
.Nav-item a {
  padding: 0 5px;
  color: #463301;
  text-decoration: none;
  font-weight: 500;
  /* -webkit-text-stroke: 0.5px #ebecea ; */
  font-size: 1.4rem;
}
.Nav-item a:hover {
  color: #592015;
  text-decoration: underline;
}
ul .Nav-item a.active {
  font-weight: bolder;
  color: #592015;
}
.App-header .MuiToolbar-root {
  justify-content: space-between;
}

/* .MuiDrawer-paper {
  background-color: #c8cdc1;
} */
.MuiDrawer-paper .Nav-item {
  flex-direction: column;
  align-items: center;
  border-bottom: #463301 1px solid;
  width: 70%;
  margin: 0 auto;
}
