.code-title {
  font-family: 'ArialHBS';
}
/* .design-title {
  font-family: 'Rancho';
} */
.project-subtitle {
  padding: 20px 0 5px;
}
.project-contents {
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.project-sub-img {
  padding: 5px;
  margin: 0 auto;
  max-height: 300px;
  max-width: 100%;
}
.project-sub-img:hover {
  max-height: 100%;
}

.MuiCard-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
