* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Amita';
  src: url('../fonts/Amita/Amita-Bold.ttf') format('TrueType');
}
@font-face {
  font-family: 'ArialHBS';
  src: url('../fonts/ArialHB.ttc') format('TrueType');
}
@font-face {
  font-family: 'Rancho';
  src: url('../fonts/Rancho/Rancho-Regular.ttf') format('truetype');
}
#root {
  min-height: 100vh;
}
/* .MuiTypography-root {
  font-family: 'ArialHBS';
} */
.max-width {
  max-width: 1980px;
  margin: 0 auto;
}
body::before {
  content: '';
  background-image: url('../img/background-leaves-top.png');
  background-position: top 0 left -15%;
  background-size: 60%;
  background-repeat: no-repeat;
  opacity: 0.3;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1000;
}

body::after {
  content: '';
  background-image: url('../img/background-leaves-bottom.png');
  background-position: bottom 0 right -15%;
  background-size: 60%;
  background-repeat: no-repeat;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: fixed;
  /* top: 0; */
  right: 0;
  bottom: 0;
  /* left: 0; */
  z-index: -1000;
}

body::backdrop {
  background-color: #ebecea;
}

.Logo {
  text-decoration: none;
  color: #463301;
}

.NavFont {
  font-family: 'ArialHBS';
}
.float-left {
  float: left;
}

h3.page-title {
  font-family: 'Rancho';
  text-align: center;
  margin: 20px 0;
}
h4.section-title {
  text-align: center;
  margin: 30px 0;
}

.align-content-center {
  display: flex;
  align-items: center;
}

.bold-text-question {
  font-weight: bold;
  display: block;
}
