.footer {
  margin-top: 20px;
  /* background-color: #979386; */
  /* position: absolute; */
  bottom: 0;
  padding-bottom: 10px;
  width: 100vw;
  max-height: 100px;
}

.footer a {
  padding: 0 20px;
}
.footer-icons {
  max-height: 25px;
  opacity: 0.8;
}
/* .footer-copyright {
  padding-bottom: 10px;
} */
