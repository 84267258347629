.ContactUs-BannerImg {
  width: 100%;
}

.contact-social-icons {
  max-height: 25px;
  filter: invert(1);
}

.contact-socials {
  display: flex;
  margin: 30px 0;
}
.contact-socials > a {
  /* padding: 0 10px; */
}
.MuiFilledInput-root {
  background-color: #f7f6f680;
}
.MuiFilledInput-root:before {
  border-bottom: none;
}
